import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/home',
    children:[
      {
        path: '/home',
        component: () => import(/* webpackChunkName: "home" */ '../views/Index.vue')
      },
      {
        path: '/client',
        component: () => import(/* webpackChunkName: "client" */ '../views/Client.vue')
      },
      {
        path: '/searchpage/:id',
        component: () => import(/* webpackChunkName: "searchpage" */ '../views/SearchPage.vue'),
        props: true
      },
      {
        path: '/game',
        component: () => import(/* webpackChunkName: "game" */ '../views/Game.vue')
      },
      {
        path: '/design',
        component: () => import(/* webpackChunkName: "design" */ '../views/Design.vue')
      },
      {
        path: '/notepad',
        component: () => import(/* webpackChunkName: "notepad" */ '../views/Notepad.vue'),
      },
      {
        path: '/notepad/:id',
        component: () => import(/* webpackChunkName: "notepad1" */ '../views/Notepad.vue'),
        props: true
      },
      {
        path: '/wangzhe/index',
        component: () => import(/* webpackChunkName: "wangzhe" */ '../views/wangzhe/index.vue')
      },
      {
        path: '/publishArticle',
        component: () => import(/* webpackChunkName: "publishArticle" */ '../views/PublishArticle.vue')
      },
      {
        path: '/publishArticle/:edit',
        component: () => import(/* webpackChunkName: "publishArticlee" */ '../views/PublishArticle.vue'),
        props: true
      },
      {
        path: '/user',
        component: () => import(/* webpackChunkName: "user" */ '../views/PersonalPage.vue'),
      },
      {
        path: '/user/:sort',
        component: () => import(/* webpackChunkName: "users" */ '../views/PersonalPage.vue'),
        props: true
      },
      {
        path: '/publishDesign',
        component: () => import(/* webpackChunkName: "publishDesign" */ '../views/PublishDesign.vue')
      },
      {
        path: '/publishDesign/:id',
        component: () => import(/* webpackChunkName: "publishDesigni" */ '../views/PublishDesign.vue'),
        props: true
      },
      {
        path: '/designInfo/:id',
        component: () => import(/* webpackChunkName: "designinfoi" */ '../views/DesignInfo.vue'),
        props: true
      },
      {
        path: '/articleInfo/:id',
        component: () => import(/* webpackChunkName: "articleingoi" */ '../views/ArticleInfo.vue'),
        props: true
      },
    ]
  },
  {
    path: '/login',
    component:() => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router