import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user:{
      username: '',
      _id:'',
      touxiang: 'http://www.sunwenliang.com/images/touxiang/touxiang1.png'
    },
    LoginState: null,
    language: "zh"
  },
  mutations: {
    userInfo(state, payload){
      // payload 必须是一个数组，数组第一项为对象，第二项为user的key
      state.user[payload[1]] = payload[0][payload[1]]
    },
    loginState(state, payload){
      // 改变登录状态，通过登录状态，判断个人用户数据的更新
      state.LoginState = payload
    },
    changeUser(state, payload){
      state.user = payload
    },
    changeTouxiang(state, payload){
      state.user.touxiang = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
