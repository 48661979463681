import Axios from 'axios'
import store from './store'

const axios = Axios.create({
    baseURL: process.env.VUE_APP_API_URL || '/client/api',
    // baseURL: 'http://localhost:3000/client/api'
})

axios.interceptors.request.use(function (config) {
    if(localStorage.token){
        config.headers.Authorization = localStorage.token
    }
    return config
}, err => {
    return Promise.reject(err)
});


export default axios