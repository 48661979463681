<template>
  <div class="home">
    <div class="nav pb-5">
      <div class="nav-header d-flex fs-15">
        <div class="d-flex ai-center">
          <router-link  tag="div" to="/home" class="mr-15 py-5 my-5 m-c-p c-hover"> <i class="iconfont icon-zhuye"></i>{{ $t("message.hello") }}</router-link>      
        </div>
        <div class="d-flex jc-end ai-center">
          <router-link  tag="div" to='/client' class="mx-15 py-5 my-5 m-c-p c-hover"> <i class="iconfont icon-js"></i> js基础 </router-link>
          <router-link  tag="div" to='/design' class="mx-15 py-5 my-5 m-c-p c-hover"> <i class="iconfont icon-shejifei"></i> 图片</router-link>
          <router-link  tag="div" to='/notepad' class="mx-15 py-5 my-5 m-c-p c-hover"> <i class="iconfont icon-19"></i> 筛选 </router-link>
          <input type="text" class="ml-20 pl-10 b-radius" v-model="searchContent"  placeholder="站内搜索" style="position:relative; top:2px" @keyup.enter="submit">
          <span class="iconfont icon-sousuo zhannei m-c-p" @click="submit"></span>
          <div class="hr-fenge"></div>
          <div v-if="username" class="touxiang ml-15 m-c-p" @click="$router.push('/user')">
            <img :src="$store.state.user.touxiang" alt="">
          </div>
          <router-link tag="div" to="/user" v-if="$store.state.user.username" class="ml-10 mx-15 m-c-p c-hover"
          @mouseenter.native="mouseEnter"
          @mouseleave.native="mouseLeave"
          >{{$store.state.user.username}}</router-link>
          <router-link tag="div" to="/login" v-if="!username" class="mx-30 m-c-p c-hover">注册</router-link>
          <router-link tag="button" type="button" to="/login" v-if="!username" class="p-5 px-10 m-c-p">登录</router-link>
        </div>
        <div class="personal-wrap" :class="{active: personalCenter}" @mouseenter="mouseEnter" @mouseleave="mouseLeave"> 
          <div class="d-flex flex-column ai-center personal-center fs-13 px-10 py-10 text-dark-2">
            <span class="m-c-p c-hover" @click="quit">退出登录</span>
          </div>
        </div>
      </div>
    </div>
      <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>

export default {
  name: 'Home',
  data(){
    return {
      personalCenter: false,
      timer: null,
      selected: '百度',
      baidu:'http://www.baidu.com/baidu',
      guge:'http://www.google.com/search',
      bing:'https://www.bing.com/search',
      isRouterAlive: true,
      searchContent: ''
    }
  },
  provide(){
    return {
      reload: this.reload
    }
  },
  methods:{
    reload(){
      this.isRouterAlive = false
      this.$nextTick(function(){this.isRouterAlive = true})
    },
    mouseEnter(){
      clearTimeout(this.timer)
      this.personalCenter = true
    },
    mouseLeave(){
      this.timer = setTimeout(() => this.personalCenter = false, 200)
    },
    quit(){
      localStorage.clear()
      this.$store.commit('userInfo', [{username: ''}, 'username'])
      this.$store.commit('changeUser', {})
      this.personalCenter = false
      this.$router.push('/')
      this.$store.commit('loginState', false)
    },
    getUserInfo(){
      this.$axios.get('/user').then(res => {
        this.$store.commit('changeUser', res.data)
        this.$store.commit('userInfo', [res.data, '_id'])
        this.$store.commit('loginState', true)
        this.selected = res.data.searchEngine
      })
    },
    submit(){
      if(!this.searchContent){
        return this.$message({type: 'warning', message:'请输入搜索内容！'})
      }
      this.reload()
      this.$router.push(`/searchpage/${this.searchContent}`)
      this.searchContent = ''
    }
  },
  computed:{
    username(){
      return this.$store.state.user.username
    }
  },
  watch: {
    selected(value){
      this.$axios.post('/searchEngine', {searchEngine: value})
    }
  },
  mounted(){
    // 检验vuex中是否有账户存在, 若不存在，并且本地有token，发送获取用户信息请求
    if(localStorage.token && !this.$store.state.username){
      this.getUserInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/variables';
.home{
  height: 100vh;
  overflow: hidden;
}
.nav{
  background: map-get($colors, 'white');
  color: map-get($colors, 'dark-2');
  box-shadow:0 2px 10px rgba(0, 0, 0, 0.12);
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  .nav-header{
    margin-left: 50%;
    justify-content: flex-end;
    transform: translate(-70rem, 0);
    width: 140rem;
    .home-index{
      align-self: flex-start;
    }
    .c-hover{
      border-bottom: 2px solid #fafafa;
    }
    .c-hover:hover{
      color: map-get($colors, 'purple');
    }
    .router-link-exact-active{
      color: map-get($colors, 'purple');
      border-bottom: 2px solid;
    }
    i{
      font-size: 2rem;
      position: relative;
      top: 0.1rem;
    }
    input{
      height: 2.5rem;
    }
    input:hover{
      border-color: map-get($colors, 'purple');
    }
    .zhannei{
      position: relative;
      top: 2px;
      margin-left: -2.5rem
    }
    .hr-fenge{
      margin-left: 40px;
      height: 20px;
      background: #aaa;
      width: 1px;
    }
    .touxiang{
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
      }
    }
    button[type=button]{
      border: none;
      background:  map-get($colors, 'purple');
      color: white;
      border-radius: 0.25rem;
    }
  .personal-wrap{
      position: absolute;
      top: 3.6rem;
      right: 0.8rem;
      height: 0;
      overflow: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      &.active{
        opacity: 1;
        height: 12rem;
        overflow: inherit;
      }
      .personal-center{
        line-height: 3.5rem;
        background: map-get($colors, 'white');
        border-radius: 5px;
        box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
        border: 1px solid #eee;
        margin-top: 12px;
      }
      .personal-center::before{
        display: block;
        width: 12px;
        height: 12px;
        background: map-get($colors, 'white');
        content: '';
        position: absolute;
        transform: translate(0,-125%) rotate(45deg);
        border-top: 1px solid #eee;
        border-left: 1px solid #eee;
      }
    }
  }
}

 .option{
  /*用div的样式代替select的样式*/
    // margin: 100px;
    display: inline-block;
    width: 6rem;
    height: 2.5rem;
    box-sizing: border-box;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    overflow: hidden;
    border: 1px solid rgb(118,118,118);
    border-right: none;
    position: relative;
    top: 0.8rem;
  }
  .option select{
    /*清除select的边框样式*/
    border: none;
    /*清除select聚焦时候的边框颜色*/
    outline: none;
    /*将select的宽高等于div的宽高*/
    width: 100%;
    height: 2.5rem;
    line-height: 2.5rem;
    /*隐藏select的下拉图标*/
    // appearance: none;
    /*通过padding-left的值让文字居中*/
    padding-left: 1rem;
  }
  .quanwang-form{
    width: 30rem;
  }
  .quanwang{
    width: 22rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    outline: none;
    border: 1px solid rgb(118,118,118);
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    line-height: 2.5rem;
  }
  .quanwang-button{
    border: none;
    margin-left: -3rem;
  }
</style>
