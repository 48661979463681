import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from './store'

Vue.use(VueI18n)

console.log(navigator.language)

const messages = {
  en: {
    message: {
      hello: ' hello world'
    }
  },
  ja: {
    message: {
      hello: ' こんにちは、世界'
    }
  },
  zh:{
      message:{
          hello: " 主页"
      }
  }
}
console.log(store)
export default new VueI18n({
  locale: store.state.language, // set locale
  messages, // set locale messages
})