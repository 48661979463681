import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './axios'
import { Scrollbar, Checkbox, CheckboxButton, CheckboxGroup, Message, MessageBox} from 'element-ui'
import i18n from './VueI18n.js'

Vue.use(Scrollbar)
Vue.use(Checkbox)
Vue.use(CheckboxButton)
Vue.use(CheckboxGroup)

Vue.prototype.$axios = axios
Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm;

import './assets/iconfont/iconfont.css'
import './assets/css/style.scss'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/element-variables.scss'


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
